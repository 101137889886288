var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("main", {}, [
      _c("div", [
        _c("h1", { staticClass: "h3 mb-3" }, [_vm._v("Messages")]),
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "row g-0" }, [
            _vm._m(0),
            _c("div", { staticClass: "col-12 col-lg-7 col-xl-9" }, [
              _c(
                "div",
                { staticClass: "py-2 px-4 border-bottom d-none d-lg-block" },
                [
                  _c("div", { staticClass: "d-flex align-items-center py-1" }, [
                    _vm._m(1),
                    _vm._m(2),
                    _c("div", [
                      _c(
                        "button",
                        { staticClass: "btn btn-primary btn-lg mr-1 px-3" },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "feather feather-phone feather-lg",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "24",
                                height: "24",
                                viewBox: "0 0 24 24",
                                fill: "none",
                                stroke: "currentColor",
                                "stroke-width": "2",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                                }
                              })
                            ]
                          )
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-info btn-lg mr-1 px-3 d-none d-md-inline-block"
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "feather feather-video feather-lg",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "24",
                                height: "24",
                                viewBox: "0 0 24 24",
                                fill: "none",
                                stroke: "currentColor",
                                "stroke-width": "2",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }
                            },
                            [
                              _c("polygon", {
                                attrs: { points: "23 7 16 12 23 17 23 7" }
                              }),
                              _c("rect", {
                                attrs: {
                                  x: "1",
                                  y: "5",
                                  width: "15",
                                  height: "14",
                                  rx: "2",
                                  ry: "2"
                                }
                              })
                            ]
                          )
                        ]
                      ),
                      _c(
                        "button",
                        { staticClass: "btn btn-light border btn-lg px-3" },
                        [
                          _c(
                            "svg",
                            {
                              staticClass:
                                "feather feather-more-horizontal feather-lg",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "24",
                                height: "24",
                                viewBox: "0 0 24 24",
                                fill: "none",
                                stroke: "currentColor",
                                "stroke-width": "2",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }
                            },
                            [
                              _c("circle", {
                                attrs: { cx: "12", cy: "12", r: "1" }
                              }),
                              _c("circle", {
                                attrs: { cx: "19", cy: "12", r: "1" }
                              }),
                              _c("circle", {
                                attrs: { cx: "5", cy: "12", r: "1" }
                              })
                            ]
                          )
                        ]
                      )
                    ])
                  ])
                ]
              ),
              _vm._m(3),
              _vm._m(4)
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-lg-5 col-xl-3 border-right" }, [
      _c("div", { staticClass: "px-4 d-none d-md-block" }, [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("div", { staticClass: "flex-grow-1" }, [
            _c("input", {
              staticClass: "form-control my-3",
              attrs: { type: "text", placeholder: "Search..." }
            })
          ])
        ])
      ]),
      _c(
        "a",
        {
          staticClass: "list-group-item list-group-item-action border-0",
          attrs: { href: "#" }
        },
        [
          _c("div", { staticClass: "badge bg-success float-right" }, [
            _vm._v("5")
          ]),
          _c("div", { staticClass: "d-flex align-items-start" }, [
            _c("img", {
              staticClass: "rounded-circle mr-1",
              attrs: {
                src: "https://bootdey.com/img/Content/avatar/avatar5.png",
                alt: "Vanessa Tucker",
                width: "40",
                height: "40"
              }
            }),
            _c("div", { staticClass: "flex-grow-1 ml-3" }, [
              _vm._v(" Vanessa Tucker "),
              _c("div", { staticClass: "small" }, [
                _c("span", { staticClass: "fas fa-circle chat-online" }),
                _vm._v(" Online")
              ])
            ])
          ])
        ]
      ),
      _c(
        "a",
        {
          staticClass: "list-group-item list-group-item-action border-0",
          attrs: { href: "#" }
        },
        [
          _c("div", { staticClass: "badge bg-success float-right" }, [
            _vm._v("2")
          ]),
          _c("div", { staticClass: "d-flex align-items-start" }, [
            _c("img", {
              staticClass: "rounded-circle mr-1",
              attrs: {
                src: "https://bootdey.com/img/Content/avatar/avatar2.png",
                alt: "William Harris",
                width: "40",
                height: "40"
              }
            }),
            _c("div", { staticClass: "flex-grow-1 ml-3" }, [
              _vm._v(" William Harris "),
              _c("div", { staticClass: "small" }, [
                _c("span", { staticClass: "fas fa-circle chat-online" }),
                _vm._v(" Online")
              ])
            ])
          ])
        ]
      ),
      _c(
        "a",
        {
          staticClass: "list-group-item list-group-item-action border-0",
          attrs: { href: "#" }
        },
        [
          _c("div", { staticClass: "d-flex align-items-start" }, [
            _c("img", {
              staticClass: "rounded-circle mr-1",
              attrs: {
                src: "https://bootdey.com/img/Content/avatar/avatar3.png",
                alt: "Sharon Lessman",
                width: "40",
                height: "40"
              }
            }),
            _c("div", { staticClass: "flex-grow-1 ml-3" }, [
              _vm._v(" Sharon Lessman "),
              _c("div", { staticClass: "small" }, [
                _c("span", { staticClass: "fas fa-circle chat-online" }),
                _vm._v(" Online")
              ])
            ])
          ])
        ]
      ),
      _c(
        "a",
        {
          staticClass: "list-group-item list-group-item-action border-0",
          attrs: { href: "#" }
        },
        [
          _c("div", { staticClass: "d-flex align-items-start" }, [
            _c("img", {
              staticClass: "rounded-circle mr-1",
              attrs: {
                src: "https://bootdey.com/img/Content/avatar/avatar4.png",
                alt: "Christina Mason",
                width: "40",
                height: "40"
              }
            }),
            _c("div", { staticClass: "flex-grow-1 ml-3" }, [
              _vm._v(" Christina Mason "),
              _c("div", { staticClass: "small" }, [
                _c("span", { staticClass: "fas fa-circle chat-offline" }),
                _vm._v(" Offline")
              ])
            ])
          ])
        ]
      ),
      _c(
        "a",
        {
          staticClass: "list-group-item list-group-item-action border-0",
          attrs: { href: "#" }
        },
        [
          _c("div", { staticClass: "d-flex align-items-start" }, [
            _c("img", {
              staticClass: "rounded-circle mr-1",
              attrs: {
                src: "https://bootdey.com/img/Content/avatar/avatar5.png",
                alt: "Fiona Green",
                width: "40",
                height: "40"
              }
            }),
            _c("div", { staticClass: "flex-grow-1 ml-3" }, [
              _vm._v(" Fiona Green "),
              _c("div", { staticClass: "small" }, [
                _c("span", { staticClass: "fas fa-circle chat-offline" }),
                _vm._v(" Offline")
              ])
            ])
          ])
        ]
      ),
      _c(
        "a",
        {
          staticClass: "list-group-item list-group-item-action border-0",
          attrs: { href: "#" }
        },
        [
          _c("div", { staticClass: "d-flex align-items-start" }, [
            _c("img", {
              staticClass: "rounded-circle mr-1",
              attrs: {
                src: "https://bootdey.com/img/Content/avatar/avatar2.png",
                alt: "Doris Wilder",
                width: "40",
                height: "40"
              }
            }),
            _c("div", { staticClass: "flex-grow-1 ml-3" }, [
              _vm._v(" Doris Wilder "),
              _c("div", { staticClass: "small" }, [
                _c("span", { staticClass: "fas fa-circle chat-offline" }),
                _vm._v(" Offline")
              ])
            ])
          ])
        ]
      ),
      _c(
        "a",
        {
          staticClass: "list-group-item list-group-item-action border-0",
          attrs: { href: "#" }
        },
        [
          _c("div", { staticClass: "d-flex align-items-start" }, [
            _c("img", {
              staticClass: "rounded-circle mr-1",
              attrs: {
                src: "https://bootdey.com/img/Content/avatar/avatar4.png",
                alt: "Haley Kennedy",
                width: "40",
                height: "40"
              }
            }),
            _c("div", { staticClass: "flex-grow-1 ml-3" }, [
              _vm._v(" Haley Kennedy "),
              _c("div", { staticClass: "small" }, [
                _c("span", { staticClass: "fas fa-circle chat-offline" }),
                _vm._v(" Offline")
              ])
            ])
          ])
        ]
      ),
      _c(
        "a",
        {
          staticClass: "list-group-item list-group-item-action border-0",
          attrs: { href: "#" }
        },
        [
          _c("div", { staticClass: "d-flex align-items-start" }, [
            _c("img", {
              staticClass: "rounded-circle mr-1",
              attrs: {
                src: "https://bootdey.com/img/Content/avatar/avatar3.png",
                alt: "Jennifer Chang",
                width: "40",
                height: "40"
              }
            }),
            _c("div", { staticClass: "flex-grow-1 ml-3" }, [
              _vm._v(" Jennifer Chang "),
              _c("div", { staticClass: "small" }, [
                _c("span", { staticClass: "fas fa-circle chat-offline" }),
                _vm._v(" Offline")
              ])
            ])
          ])
        ]
      ),
      _c("hr", { staticClass: "d-block d-lg-none mt-1 mb-0" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "position-relative" }, [
      _c("img", {
        staticClass: "rounded-circle mr-1",
        attrs: {
          src: "https://bootdey.com/img/Content/avatar/avatar3.png",
          alt: "Sharon Lessman",
          width: "40",
          height: "40"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-grow-1 pl-3" }, [
      _c("strong", [_vm._v("Sharon Lessman")]),
      _c("div", { staticClass: "text-muted small" }, [
        _c("em", [_vm._v("Typing...")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "position-relative" }, [
      _c("div", { staticClass: "chat-messages p-4" }, [
        _c("div", { staticClass: "chat-message-right pb-4" }, [
          _c("div", [
            _c("img", {
              staticClass: "rounded-circle mr-1",
              attrs: {
                src: "https://bootdey.com/img/Content/avatar/avatar1.png",
                alt: "Chris Wood",
                width: "40",
                height: "40"
              }
            }),
            _c("div", { staticClass: "text-muted small text-nowrap mt-2" }, [
              _vm._v("2:33 am")
            ])
          ]),
          _c(
            "div",
            { staticClass: "flex-shrink-1 bg-light rounded py-2 px-3 mr-3" },
            [
              _c("div", { staticClass: "font-weight-bold mb-1" }, [
                _vm._v("You")
              ]),
              _vm._v(
                " Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix. "
              )
            ]
          )
        ]),
        _c("div", { staticClass: "chat-message-left pb-4" }, [
          _c("div", [
            _c("img", {
              staticClass: "rounded-circle mr-1",
              attrs: {
                src: "https://bootdey.com/img/Content/avatar/avatar3.png",
                alt: "Sharon Lessman",
                width: "40",
                height: "40"
              }
            }),
            _c("div", { staticClass: "text-muted small text-nowrap mt-2" }, [
              _vm._v("2:34 am")
            ])
          ]),
          _c(
            "div",
            { staticClass: "flex-shrink-1 bg-light rounded py-2 px-3 ml-3" },
            [
              _c("div", { staticClass: "font-weight-bold mb-1" }, [
                _vm._v("Sharon Lessman")
              ]),
              _vm._v(
                " Sit meis deleniti eu, pri vidit meliore docendi ut, an eum erat animal commodo. "
              )
            ]
          )
        ]),
        _c("div", { staticClass: "chat-message-right mb-4" }, [
          _c("div", [
            _c("img", {
              staticClass: "rounded-circle mr-1",
              attrs: {
                src: "https://bootdey.com/img/Content/avatar/avatar1.png",
                alt: "Chris Wood",
                width: "40",
                height: "40"
              }
            }),
            _c("div", { staticClass: "text-muted small text-nowrap mt-2" }, [
              _vm._v("2:35 am")
            ])
          ]),
          _c(
            "div",
            { staticClass: "flex-shrink-1 bg-light rounded py-2 px-3 mr-3" },
            [
              _c("div", { staticClass: "font-weight-bold mb-1" }, [
                _vm._v("You")
              ]),
              _vm._v(" Cum ea graeci tractatos. ")
            ]
          )
        ]),
        _c("div", { staticClass: "chat-message-left pb-4" }, [
          _c("div", [
            _c("img", {
              staticClass: "rounded-circle mr-1",
              attrs: {
                src: "https://bootdey.com/img/Content/avatar/avatar3.png",
                alt: "Sharon Lessman",
                width: "40",
                height: "40"
              }
            }),
            _c("div", { staticClass: "text-muted small text-nowrap mt-2" }, [
              _vm._v("2:36 am")
            ])
          ]),
          _c(
            "div",
            { staticClass: "flex-shrink-1 bg-light rounded py-2 px-3 ml-3" },
            [
              _c("div", { staticClass: "font-weight-bold mb-1 " }, [
                _vm._v("Sharon Lessman")
              ]),
              _vm._v(
                " Sed pulvinar, massa vitae interdum pulvinar, risus lectus porttitor magna, vitae commodo lectus mauris et velit. Proin ultricies placerat imperdiet. Morbi varius quam ac venenatis tempus. "
              )
            ]
          )
        ]),
        _c("div", { staticClass: "chat-message-left pb-4" }, [
          _c("div", [
            _c("img", {
              staticClass: "rounded-circle mr-1",
              attrs: {
                src: "https://bootdey.com/img/Content/avatar/avatar3.png",
                alt: "Sharon Lessman",
                width: "40",
                height: "40"
              }
            }),
            _c("div", { staticClass: "text-muted small text-nowrap mt-2" }, [
              _vm._v("2:37 am")
            ])
          ]),
          _c(
            "div",
            { staticClass: "flex-shrink-1 bg-light rounded py-2 px-3 ml-3" },
            [
              _c("div", { staticClass: "font-weight-bold mb-1" }, [
                _vm._v("Sharon Lessman")
              ]),
              _vm._v(
                " Cras pulvinar, sapien id vehicula aliquet, diam velit elementum orci. "
              )
            ]
          )
        ]),
        _c("div", { staticClass: "chat-message-right mb-4" }, [
          _c("div", [
            _c("img", {
              staticClass: "rounded-circle mr-1",
              attrs: {
                src: "https://bootdey.com/img/Content/avatar/avatar1.png",
                alt: "Chris Wood",
                width: "40",
                height: "40"
              }
            }),
            _c("div", { staticClass: "text-muted small text-nowrap mt-2" }, [
              _vm._v("2:38 am")
            ])
          ]),
          _c(
            "div",
            { staticClass: "flex-shrink-1 bg-light rounded py-2 px-3 mr-3" },
            [
              _c("div", { staticClass: "font-weight-bold mb-1" }, [
                _vm._v("You")
              ]),
              _vm._v(
                " Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix. "
              )
            ]
          )
        ]),
        _c("div", { staticClass: "chat-message-left pb-4" }, [
          _c("div", [
            _c("img", {
              staticClass: "rounded-circle mr-1",
              attrs: {
                src: "https://bootdey.com/img/Content/avatar/avatar3.png",
                alt: "Sharon Lessman",
                width: "40",
                height: "40"
              }
            }),
            _c("div", { staticClass: "text-muted small text-nowrap mt-2" }, [
              _vm._v("2:39 am")
            ])
          ]),
          _c(
            "div",
            { staticClass: "flex-shrink-1 bg-light rounded py-2 px-3 ml-3" },
            [
              _c("div", { staticClass: "font-weight-bold mb-1" }, [
                _vm._v("Sharon Lessman")
              ]),
              _vm._v(
                " Sit meis deleniti eu, pri vidit meliore docendi ut, an eum erat animal commodo. "
              )
            ]
          )
        ]),
        _c("div", { staticClass: "chat-message-right mb-4" }, [
          _c("div", [
            _c("img", {
              staticClass: "rounded-circle mr-1",
              attrs: {
                src: "https://bootdey.com/img/Content/avatar/avatar1.png",
                alt: "Chris Wood",
                width: "40",
                height: "40"
              }
            }),
            _c("div", { staticClass: "text-muted small text-nowrap mt-2" }, [
              _vm._v("2:40 am")
            ])
          ]),
          _c(
            "div",
            { staticClass: "flex-shrink-1 bg-light rounded py-2 px-3 mr-3" },
            [
              _c("div", { staticClass: "font-weight-bold mb-1" }, [
                _vm._v("You")
              ]),
              _vm._v(" Cum ea graeci tractatos. ")
            ]
          )
        ]),
        _c("div", { staticClass: "chat-message-right mb-4" }, [
          _c("div", [
            _c("img", {
              staticClass: "rounded-circle mr-1",
              attrs: {
                src: "https://bootdey.com/img/Content/avatar/avatar1.png",
                alt: "Chris Wood",
                width: "40",
                height: "40"
              }
            }),
            _c("div", { staticClass: "text-muted small text-nowrap mt-2" }, [
              _vm._v("2:41 am")
            ])
          ]),
          _c(
            "div",
            { staticClass: "flex-shrink-1 bg-light rounded py-2 px-3 mr-3" },
            [
              _c("div", { staticClass: "font-weight-bold mb-1" }, [
                _vm._v("You")
              ]),
              _vm._v(
                " Morbi finibus, lorem id placerat ullamcorper, nunc enim ultrices massa, id dignissim metus urna eget purus. "
              )
            ]
          )
        ]),
        _c("div", { staticClass: "chat-message-left pb-4" }, [
          _c("div", [
            _c("img", {
              staticClass: "rounded-circle mr-1",
              attrs: {
                src: "https://bootdey.com/img/Content/avatar/avatar3.png",
                alt: "Sharon Lessman",
                width: "40",
                height: "40"
              }
            }),
            _c("div", { staticClass: "text-muted small text-nowrap mt-2" }, [
              _vm._v("2:42 am")
            ])
          ]),
          _c(
            "div",
            { staticClass: "flex-shrink-1 bg-light rounded py-2 px-3 ml-3" },
            [
              _c("div", { staticClass: "font-weight-bold mb-1" }, [
                _vm._v("Sharon Lessman")
              ]),
              _vm._v(
                " Sed pulvinar, massa vitae interdum pulvinar, risus lectus porttitor magna, vitae commodo lectus mauris et velit. Proin ultricies placerat imperdiet. Morbi varius quam ac venenatis tempus. "
              )
            ]
          )
        ]),
        _c("div", { staticClass: "chat-message-right mb-4" }, [
          _c("div", [
            _c("img", {
              staticClass: "rounded-circle mr-1",
              attrs: {
                src: "https://bootdey.com/img/Content/avatar/avatar1.png",
                alt: "Chris Wood",
                width: "40",
                height: "40"
              }
            }),
            _c("div", { staticClass: "text-muted small text-nowrap mt-2" }, [
              _vm._v("2:43 am")
            ])
          ]),
          _c(
            "div",
            { staticClass: "flex-shrink-1 bg-light rounded py-2 px-3 mr-3" },
            [
              _c("div", { staticClass: "font-weight-bold mb-1" }, [
                _vm._v("You")
              ]),
              _vm._v(
                " Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix. "
              )
            ]
          )
        ]),
        _c("div", { staticClass: "chat-message-left pb-4" }, [
          _c("div", [
            _c("img", {
              staticClass: "rounded-circle mr-1",
              attrs: {
                src: "https://bootdey.com/img/Content/avatar/avatar3.png",
                alt: "Sharon Lessman",
                width: "40",
                height: "40"
              }
            }),
            _c("div", { staticClass: "text-muted small text-nowrap mt-2" }, [
              _vm._v("2:44 am")
            ])
          ]),
          _c(
            "div",
            { staticClass: "flex-shrink-1 bg-light rounded py-2 px-3 ml-3" },
            [
              _c("div", { staticClass: "font-weight-bold mb-1" }, [
                _vm._v("Sharon Lessman")
              ]),
              _vm._v(
                " Sit meis deleniti eu, pri vidit meliore docendi ut, an eum erat animal commodo. "
              )
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-grow-0 py-3 px-4 border-top" }, [
      _c("div", { staticClass: "input-group" }, [
        _c("input", {
          staticClass: "form-control mr-1",
          attrs: { type: "text", placeholder: "Type your message" }
        }),
        _c("button", { staticClass: "btn btn-primary" }, [_vm._v("Send")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }